export default {
  BODY: 'BODY',
  // removed
  SECTION: 'SECTION',
  //removed
  ROW: 'ROW',
  //removed
  COLUMN: 'COLUMN',
  //removed
  COLUMN_3: 'COLUMN_3',
  //removed
  COLUMN_4: 'COLUMN_4',
  //removed
  COLUMN_6: 'COLUMN_6',
  //removed
  COLUMN_8: 'COLUMN_8',
  //removed
  COLUMN_12: 'COLUMN_12',
  TEXT: 'TEXT',
  BULLET_LIST: 'BULLET_LIST',
  TEXT_HEADLINE: 'TEXT_HEADLINE',
  BUTTON: 'BUTTON',
  FORM_INPUT: 'FORM_INPUT',
  START_TIMER: 'START_TIMER',
  //removed
  WEBINAR_REGISTRATION_DATE: 'WEBINAR_REGISTRATION_DATE',
  WEBINAR_SESSION_DATE_TIME: 'WEBINAR_SESSION_DATE_TIME',
  //removed
  WEBINAR_SESSION_LINK: 'WEBINAR_SESSION_LINK',
  WEBINAR_SESSION_CALL_TO_ACTION: 'WEBINAR_SESSION_CALL_TO_ACTION',
  WEBINAR_SESSION_VIDEO: 'WEBINAR_SESSION_VIDEO',
  IMAGE: 'IMAGE',
  POPUP: 'POPUP',
  REMOTE_POPUP: 'REMOTE_POPUP',
  INLINE: 'INLINE',
  VIDEO: 'VIDEO', //removed
  AUDIO: 'AUDIO',
  //removed
  CONTENT_BOX: 'CONTENT_BOX',
  //removed
  FB_COMMENTS: 'FB_COMMENTS',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  CUSTOMER_TYPE: 'CUSTOMER_TYPE',
  OFFER_PRICE: 'OFFER_PRICE',
  RAW_HTML: 'RAW_HTML', //removed
  PAYMENT_BUTTON: 'PAYMENT_BUTTON',
  PAYMENT_BUTTON_AGREE: 'PAYMENT_BUTTON_AGREE', //removed
  PAYMENT_BUTTON_DISAGREE: 'PAYMENT_BUTTON_DISAGREE', //removed
  ORDER_BUMP: 'ORDER_BUMP',
  EXPLICIT_CONSENT: 'EXPLICIT_CONSENT',
  AGREEMENT: 'AGREEMENT',
  ORDER_SUMMARY: 'ORDER_SUMMARY', //removed
  //removed
  COUNTDOWN: 'COUNTDOWN',
  PHYSICAL_PRODUCT: 'PHYSICAL_PRODUCT',
  SALES_REDIRECTION_BUTTON: 'SALES_REDIRECTION_BUTTON',
  COUPON: 'COUPON',
  BLOG_POST_BODY: 'BLOG_POST_BODY',
  BLOG_PAGE_BODY: 'BLOG_PAGE_BODY',
  BLOG_POST_LAYOUT_BODY: 'BLOG_POST_LAYOUT_BODY',
  BLOG_POST_TITLE: 'BLOG_POST_TITLE', //removed
  BLOG_POST_DATE: 'BLOG_POST_DATE', //removed
  BLOG_POST_CATEGORIES: 'BLOG_POST_CATEGORIES', //removed
  BLOG_POST_CONTENT_PLACEHOLDER: 'BLOG_POST_CONTENT_PLACEHOLDER',
  BLOG_CONTENT_PLACEHOLDER: 'BLOG_CONTENT_PLACEHOLDER',
  BLOG_POST_LISTING: 'BLOG_POST_LISTING',
  LATEST_BLOG_POSTS: 'LATEST_BLOG_POSTS',
  //removed
  MENU: 'MENU',
  LANGUAGE_SWITCHER: 'LANGUAGE_SWITCHER', //removed
  TWO_STEP_PAYMENT_FORM: 'TWO_STEP_PAYMENT_FORM',
  TWO_STEP_PAYMENT_FORM_STEP_OPT_IN: 'TWO_STEP_PAYMENT_FORM_STEP_OPT_IN',
  TWO_STEP_PAYMENT_FORM_STEP_PAYMENT: 'TWO_STEP_PAYMENT_FORM_STEP_PAYMENT',
  SIDE_MENU_OPENED: 'SIDE_MENU_OPENED',
  //removed
  HORIZONTAL_LINE: 'HORIZONTAL_LINE',
  //removed
  TWITTER_TWEET_BUTTON: 'TWITTER_TWEET_BUTTON',
  SURVEY: 'SURVEY',
  CAROUSEL: 'CAROUSEL', // removed
  CAROUSEL_ITEM: 'CAROUSEL_ITEM', // removed
}
