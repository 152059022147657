import React from 'react'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import { Coupon as CouponType } from 'common/types/CouponType'
import { PricePlan as PricePlanType } from 'common/types/OfferInterface'
import {
  centsToEuro,
  getNetPrice,
  getPricePlanAmount,
  getReadablePrice,
} from 'common/utils/priceCalculator'
import Coupon from '../Coupon'
import Total from '../Total'
import Vat from '../Vat'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanDescriptionUi from '../ui/PricePlanDescriptionUi'
import PricePlanItemUi from '../ui/PricePlanItemUi'
import PricePlanItemWithoutLeftOffsetUi from '../ui/PricePlanItemWithoutLeftOffsetUi'
import PricePlanLabelUi from '../ui/PricePlanLabelUi'
import PricePlanNameUi from '../ui/PricePlanNameUi'
import PricePlanUi from '../ui/PricePlanUi'
import LimitedSubscriptionPlan from './LimitedSubscriptionPlan'
import SubscriptionPlan from './SubscriptionPlan'

export type PricePlanProps = {
  plan: PricePlanType
  isActive?: boolean
  coupon?: CouponType
  quantity?: number
  customDescription?: string
  tax: number
  togglePricePlan?: (id: number) => void
  locale: string
  nameFontFamily?: string
  mobileNameFontFamily?: string
  nameFontSize: number
  mobileNameFontSize?: number
  nameFontStyle?: string
  mobileNameFontStyle?: string
  nameFontWeight?: string
  mobileNameFontWeight?: string
  nameLineHeight: number
  mobileNameLineHeight?: number
  nameColor: string
  mobileNameColor?: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
  dataTestId: string
}

function PricePlan({
  plan,
  isActive,
  coupon,
  quantity = 1,
  togglePricePlan,
  tax,
  customDescription,
  locale,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  mobileNameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
  dataTestId,
}: PricePlanProps) {
  const needTotal = tax > 0 || !!coupon
  return (
    <PricePlanUi>
      <PricePlanItemWithoutLeftOffsetUi>
        <PricePlanNameUi
          fontSize={nameFontSize}
          mobileFontSize={mobileNameFontSize}
          fontStyle={nameFontStyle}
          mobileFontStyle={mobileNameFontStyle}
          fontWeight={nameFontWeight}
          mobileFontWeight={mobileNameFontWeight}
          fontFamily={nameFontFamily}
          mobileFontFamily={mobileNameFontFamily}
          lineHeight={nameLineHeight}
          mobileLineHeight={mobileNameLineHeight}
          color={nameColor}
          mobileColor={mobileNameColor}
          data-test-id={dataTestId}
        >
          {!togglePricePlan ? (
            plan.name
          ) : (
            <PricePlanLabelUi>
              <input
                type="radio"
                checked={isActive}
                onChange={() => togglePricePlan(plan.id)}
              />{' '}
              <div>{plan.name}</div>
            </PricePlanLabelUi>
          )}
        </PricePlanNameUi>
        <PricePlanAmountUi
          fontSize={amountFontSize}
          mobileFontSize={mobileAmountFontSize}
          fontStyle={amountFontStyle}
          mobileFontStyle={mobileAmountFontStyle}
          fontWeight={amountFontWeight}
          mobileFontWeight={mobileAmountFontWeight}
          fontFamily={amountFontFamily}
          mobileFontFamily={mobileAmountFontFamily}
          lineHeight={amountLineHeight}
          mobileLineHeight={mobileAmountLineHeight}
          color={amountColor}
          mobileColor={mobileAmountColor}
        >
          <ReadablePricePlanAmount
            plan={plan}
            quantity={quantity}
            locale={locale}
          />
        </PricePlanAmountUi>
      </PricePlanItemWithoutLeftOffsetUi>
      {customDescription && (
        <PricePlanItemUi needBottomOffset={isActive && needTotal}>
          <PricePlanDescriptionUi
            fontSize={descriptionFontSize}
            mobileFontSize={mobileDescriptionFontSize}
            fontStyle={descriptionFontStyle}
            mobileFontStyle={mobileDescriptionFontStyle}
            fontWeight={descriptionFontWeight}
            mobileFontWeight={mobileDescriptionFontWeight}
            fontFamily={descriptionFontFamily}
            mobileFontFamily={mobileDescriptionFontFamily}
            lineHeight={descriptionLineHeight}
            mobileLineHeight={mobileDescriptionLineHeight}
            color={descriptionColor}
            mobileColor={mobileDescriptionColor}
          >
            {customDescription}
          </PricePlanDescriptionUi>
        </PricePlanItemUi>
      )}
      {isActive && (
        <>
          {tax > 0 && (
            <Vat
              plan={plan}
              quantity={quantity}
              tax={tax}
              locale={locale}
              amountFontSize={amountFontSize}
              mobileAmountFontSize={mobileAmountFontSize}
              amountFontStyle={amountFontStyle}
              mobileAmountFontStyle={mobileAmountFontStyle}
              amountFontWeight={amountFontWeight}
              mobileAmountFontWeight={mobileAmountFontWeight}
              amountFontFamily={amountFontFamily}
              mobileAmountFontFamily={mobileAmountFontFamily}
              amountLineHeight={amountLineHeight}
              mobileAmountLineHeight={mobileAmountLineHeight}
              amountColor={amountColor}
              mobileAmountColor={mobileAmountColor}
              descriptionFontSize={descriptionFontSize}
              mobileDescriptionFontSize={mobileDescriptionFontSize}
              descriptionFontStyle={descriptionFontStyle}
              mobileDescriptionFontStyle={mobileDescriptionFontStyle}
              descriptionFontWeight={descriptionFontWeight}
              mobileDescriptionFontWeight={mobileDescriptionFontWeight}
              descriptionFontFamily={descriptionFontFamily}
              mobileDescriptionFontFamily={mobileDescriptionFontFamily}
              descriptionLineHeight={descriptionLineHeight}
              mobileDescriptionLineHeight={mobileDescriptionLineHeight}
              descriptionColor={descriptionColor}
              mobileDescriptionColor={mobileDescriptionColor}
            />
          )}
          {coupon && (
            <Coupon
              amountFontSize={amountFontSize}
              mobileAmountFontSize={mobileAmountFontSize}
              amountFontStyle={amountFontStyle}
              mobileAmountFontStyle={mobileAmountFontStyle}
              amountFontWeight={amountFontWeight}
              mobileAmountFontWeight={mobileAmountFontWeight}
              amountFontFamily={amountFontFamily}
              mobileAmountFontFamily={mobileAmountFontFamily}
              amountLineHeight={amountLineHeight}
              mobileAmountLineHeight={mobileAmountLineHeight}
              amountColor={amountColor}
              mobileAmountColor={mobileAmountColor}
              descriptionFontSize={descriptionFontSize}
              mobileDescriptionFontSize={mobileDescriptionFontSize}
              descriptionFontStyle={descriptionFontStyle}
              mobileDescriptionFontStyle={mobileDescriptionFontStyle}
              descriptionFontWeight={descriptionFontWeight}
              mobileDescriptionFontWeight={mobileDescriptionFontWeight}
              descriptionFontFamily={descriptionFontFamily}
              mobileDescriptionFontFamily={mobileDescriptionFontFamily}
              descriptionLineHeight={descriptionLineHeight}
              mobileDescriptionLineHeight={mobileDescriptionLineHeight}
              descriptionColor={descriptionColor}
              mobileDescriptionColor={mobileDescriptionColor}
              coupon={coupon}
              locale={locale}
            />
          )}
          {needTotal && (
            <Total
              coupon={coupon}
              plan={plan}
              quantity={quantity}
              tax={tax}
              locale={locale}
              amountFontSize={amountFontSize}
              mobileAmountFontSize={mobileAmountFontSize}
              amountFontStyle={amountFontStyle}
              mobileAmountFontStyle={mobileAmountFontStyle}
              amountFontWeight={amountFontWeight}
              mobileAmountFontWeight={mobileAmountFontWeight}
              amountFontFamily={amountFontFamily}
              mobileAmountFontFamily={mobileAmountFontFamily}
              amountLineHeight={amountLineHeight}
              mobileAmountLineHeight={mobileAmountLineHeight}
              amountColor={amountColor}
              mobileAmountColor={mobileAmountColor}
              descriptionFontSize={descriptionFontSize}
              mobileDescriptionFontSize={mobileDescriptionFontSize}
              descriptionFontStyle={descriptionFontStyle}
              mobileDescriptionFontStyle={mobileDescriptionFontStyle}
              descriptionFontWeight={descriptionFontWeight}
              mobileDescriptionFontWeight={mobileDescriptionFontWeight}
              descriptionFontFamily={descriptionFontFamily}
              mobileDescriptionFontFamily={mobileDescriptionFontFamily}
              descriptionLineHeight={descriptionLineHeight}
              mobileDescriptionLineHeight={mobileDescriptionLineHeight}
              descriptionColor={descriptionColor}
              mobileDescriptionColor={mobileDescriptionColor}
            />
          )}
        </>
      )}
    </PricePlanUi>
  )
}

export function ReadablePricePlanAmount({
  plan,
  quantity = 1,
  coupon,
  tax = 0,
  locale,
}: {
  plan: PricePlanType
  quantity?: number
  coupon?: CouponType
  tax?: number
  locale: string
}) {
  const amountWithCurrency = getReadableAmount(
    plan,
    locale,
    quantity,
    tax,
    coupon,
  )
  switch (plan.type) {
    case PricePlanTypeEnum.OneShot:
      return <>{amountWithCurrency}</>
    case PricePlanTypeEnum.LimitedSubscription: {
      return (
        <LimitedSubscriptionPlan
          trialInterval={plan.subscriptionPlan.trialInterval}
          trialPeriod={plan.subscriptionPlan.trialPeriod}
          interval={plan.subscriptionPlan.interval}
          intervalCount={plan.subscriptionPlan.intervalCount}
          limitOfPayments={plan.subscriptionPlan.limitOfPayments}
          amountWithCurrency={amountWithCurrency}
        />
      )
    }
    case PricePlanTypeEnum.Subscription:
      return (
        <SubscriptionPlan
          trialInterval={plan.subscriptionPlan.trialInterval}
          trialPeriod={plan.subscriptionPlan.trialPeriod}
          interval={plan.subscriptionPlan.interval}
          intervalCount={plan.subscriptionPlan.intervalCount}
          amountWithCurrency={amountWithCurrency}
        />
      )
    default:
      return <div />
  }
}

export function getReadableAmount(
  plan: PricePlanType,
  locale: string,
  quantity: number,
  tax: number,
  coupon?: CouponType,
) {
  const netPrice = getNetPrice(getPricePlanAmount(plan), quantity, coupon)
  return getReadablePrice(
    centsToEuro(netPrice + tax * quantity),
    plan.currency,
    locale,
  )
}

export default PricePlan
