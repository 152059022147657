import React from 'react'
import Coupon from '../Coupon'
import Total from '../Total'
import Vat from '../Vat'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanItemUi from '../ui/PricePlanItemUi'
import PricePlanNameUi from '../ui/PricePlanNameUi'
import PricePlanUi from '../ui/PricePlanUi'
import { PricePlanProps, ReadablePricePlanAmount } from './index'

function BumpPricePlan({
  plan,
  coupon,
  tax,
  locale,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  mobileNameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
  dataTestId,
}: PricePlanProps) {
  const needTotal = tax > 0 || !!coupon

  return (
    <PricePlanUi>
      <PricePlanItemUi>
        <PricePlanNameUi
          fontSize={nameFontSize}
          mobileFontSize={mobileNameFontSize}
          fontStyle={nameFontStyle}
          mobileFontStyle={mobileNameFontStyle}
          fontWeight={nameFontWeight}
          mobileFontWeight={mobileNameFontWeight}
          fontFamily={nameFontFamily}
          mobileFontFamily={mobileNameFontFamily}
          lineHeight={nameLineHeight}
          mobileLineHeight={mobileNameLineHeight}
          color={nameColor}
          mobileColor={mobileNameColor}
          data-test-id={dataTestId}
        >
          {plan.name}
        </PricePlanNameUi>
        <PricePlanAmountUi
          fontSize={amountFontSize}
          mobileFontSize={mobileAmountFontSize}
          fontStyle={amountFontStyle}
          mobileFontStyle={mobileAmountFontStyle}
          fontWeight={amountFontWeight}
          mobileFontWeight={mobileAmountFontWeight}
          fontFamily={amountFontFamily}
          mobileFontFamily={mobileAmountFontFamily}
          lineHeight={amountLineHeight}
          mobileLineHeight={mobileAmountLineHeight}
          color={amountColor}
          mobileColor={mobileAmountColor}
        >
          <ReadablePricePlanAmount plan={plan} quantity={1} locale={locale} />
        </PricePlanAmountUi>
      </PricePlanItemUi>
      {tax > 0 && (
        <Vat
          plan={plan}
          quantity={1}
          tax={tax}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
          mobileDescriptionColor={mobileDescriptionColor}
        />
      )}
      {coupon && (
        <Coupon
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
          mobileDescriptionColor={mobileDescriptionColor}
          coupon={coupon}
          locale={locale}
        />
      )}
      {needTotal && (
        <Total
          coupon={coupon}
          plan={plan}
          quantity={1}
          tax={tax}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
          mobileDescriptionColor={mobileDescriptionColor}
        />
      )}
    </PricePlanUi>
  )
}

export default BumpPricePlan
