import { useTranslation } from 'react-i18next'
import React from 'react'
import { CouponTypeEnum } from 'common/enums/CouponTypeEnum'
import { Coupon as CouponType } from 'common/types/CouponType'
import { getReadablePrice, centsToEuro } from 'common/utils/priceCalculator'
import PricePlanAmountUi from './ui/PricePlanAmountUi'
import PricePlanDescriptionUi from './ui/PricePlanDescriptionUi'
import PricePlanItemUi from './ui/PricePlanItemUi'

type CouponProps = {
  coupon: CouponType
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
}

function Coupon({
  coupon,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
}: CouponProps) {
  const { t } = useTranslation(['common', 'publisher'])
  return (
    <PricePlanItemUi>
      <PricePlanDescriptionUi
        fontSize={descriptionFontSize}
        mobileFontSize={mobileDescriptionFontSize}
        fontStyle={descriptionFontStyle}
        mobileFontStyle={mobileDescriptionFontStyle}
        fontWeight={descriptionFontWeight}
        mobileFontWeight={mobileDescriptionFontWeight}
        fontFamily={descriptionFontFamily}
        mobileFontFamily={mobileDescriptionFontFamily}
        lineHeight={descriptionLineHeight}
        mobileLineHeight={mobileDescriptionLineHeight}
        color={descriptionColor}
        mobileColor={mobileDescriptionColor}
        data-test-id={`offer-price-coupon-${coupon.id}`}
      >
        {t('components.offer_price.one_shots_summary.discount')}
      </PricePlanDescriptionUi>
      <PricePlanAmountUi
        fontSize={amountFontSize}
        mobileFontSize={mobileAmountFontSize}
        fontStyle={amountFontStyle}
        mobileFontStyle={mobileAmountFontStyle}
        fontWeight={amountFontWeight}
        mobileFontWeight={mobileAmountFontWeight}
        fontFamily={amountFontFamily}
        mobileFontFamily={mobileAmountFontFamily}
        lineHeight={amountLineHeight}
        mobileLineHeight={mobileAmountLineHeight}
        color={amountColor}
        mobileColor={mobileAmountColor}
      >
        {' '}
        -
        {coupon.discountType === CouponTypeEnum.Fixed
          ? getReadablePrice(
              centsToEuro(coupon.discountAmount),
              coupon.currency,
              locale,
            )
          : `${coupon.discountAmount}%`}
      </PricePlanAmountUi>
    </PricePlanItemUi>
  )
}

export default Coupon
